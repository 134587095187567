define("orion-abbey/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii"], function (_exports, _torii) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _torii.default.extend({
    torii: Ember.inject.service()
  });

  _exports.default = _default;
});