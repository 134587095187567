define("orion-abbey/routes/event-log", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "lodash"], function (_exports, _authenticatedRouteMixin, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    global: Ember.inject.service(),
    session: Ember.inject.service(),
    punchCard: null,
    user: null,

    model() {
      // Get last 5 days and get last match. In case the event is overlapping days
      // If no matches, redirect to manage
      // Note: Not doing findAll bc the software will run on tablet and I don't know of the specs
      return Ember.RSVP.hash({
        user: this.get('global').getCurrentUserPromise(),
        punchCards: Ember.RSVP.all(this.get('global').getPunchCardsPromises())
      });
    },

    afterModel(model) {
      // transition to manage route if no punch-cards found for current company
      let companyId = model.user.get('firstObject.company.id');
      let punchCards = (0, _lodash.flatten)(model.punchCards.toArray().map(val => val.toArray()));
      if ((0, _lodash.isEmpty)(punchCards.filterBy('company.id', companyId))) this.transitionTo('manage');
    },

    setupController(controller, model) {
      const user = model.user.get('firstObject');
      const company = user.get('company');
      const punchCards = (0, _lodash.flatten)(model.punchCards.toArray().map(val => val.toArray())).filterBy('company.id', company.get('id'));
      const latestPunchCard = punchCards.get('firstObject');
      controller.setProperties({
        punchCard: latestPunchCard,
        user: user
      });
    }

  });

  _exports.default = _default;
});