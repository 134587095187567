define("orion-abbey/components/personnel-form", ["exports", "lodash", "orion-abbey/utils/validate-email"], function (_exports, _lodash, _validateEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    title: null,
    email: null,
    company: null,

    init() {
      this._super(...arguments);

      this.set('company', this.get('user.company').content);
    },

    actions: {
      add() {
        let company = this.get('company');
        let title = this.get('title');
        let email = this.get('email');

        if ((0, _lodash.isEmpty)(title) || (0, _lodash.isEmpty)(email)) {
          this.get('notificationMessages').error("Either email or title is missing", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        if (!(0, _validateEmail.default)(email)) {
          this.get('notificationMessages').warning("Please enter a valid email", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        let personnel = this.get('store').createRecord('personnel', {
          title: title,
          email: email,
          company: company
        });
        company.get('personnels').addObject(personnel);
        personnel.save().then(() => company.save()).then(() => {
          this.setProperties({
            title: null,
            email: null
          });
        });
      },

      removePersonnel(personnel) {
        personnel.destroyRecord().then(() => {
          this.get('company').save();
          this.get('notificationMessages').success("Personnel removed successfully!", {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }

    }
  });

  _exports.default = _default;
});