define("orion-abbey/components/employees-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      removeEmployee(employee) {
        var company = employee.get('company').content;
        employee.destroyRecord().then(() => company.save()).then(() => {
          this.get('notificationMessages').success("Employee deleted!", {
            autoClear: true,
            clearDuration: 2000
          });
        }).catch(err => {
          console.log(err);
          this.get('notificationMessages').warning("Couldn't delete employee.", {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }

    }
  });

  _exports.default = _default;
});