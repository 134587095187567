define("orion-abbey/services/global", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    getCurrentUserPromise() {
      return this.get('store').query('user', {
        orderBy: 'email',
        equalTo: this.get('session').data.authenticated.email
      });
    },

    getPunchCardsPromises() {
      const extraDaysTocheck = 5;
      return (0, _lodash.chain)([...(0, _lodash.range)(extraDaysTocheck)]).map(num => {
        let eventId = (0, _moment.default)().subtract(num, 'days').format('MMDDYYYY').toString();
        return this.store.query('punch-card', {
          orderBy: 'eventId',
          equalTo: eventId
        });
      }).value();
    }

  });

  _exports.default = _default;
});