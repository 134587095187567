define("orion-abbey/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    isManager: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isOwner: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});