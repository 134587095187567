define("orion-abbey/services/ajaxx", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: 'https://localhost:3000'
  });

  _exports.default = _default;
});