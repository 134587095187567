define("orion-abbey/helpers/signing-text", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingText = signingText;
  _exports.default = void 0;

  function signingText([timestamp, type]) {
    let text;

    switch (type) {
      case 'in':
        if (!(0, _lodash.isNil)(timestamp)) text = _moment.default.unix(timestamp).format('MMM D, h:mm A');else text = 'Sign In';
        break;

      case 'out':
        if (!(0, _lodash.isNil)(timestamp)) text = _moment.default.unix(timestamp).format('MMM D, h:mm A');else text = 'Sign Out';
        break;

      default:
    }

    return text;
  }

  var _default = Ember.Helper.helper(signingText);

  _exports.default = _default;
});