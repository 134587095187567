define("orion-abbey/controllers/event-log", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    reviewMode: false,
    showPromptDialog: false,
    isSubmitting: false,
    user: null,
    punchCard: null,
    reviewText: Ember.computed('reviewMode', 'punchCard.isSubmitted', function () {
      let isReviewing = this.get('reviewMode');
      let isSubmitted = this.get('punchCard.isSubmitted');
      if (isReviewing && isSubmitted) return 'Resubmit';
      return isReviewing ? 'Submit' : 'Review';
    }),
    actions: {
      signIn(employee) {
        employee.setProperties({
          signInTS: (0, _moment.default)().unix(),
          signInBy: this.get('user.email')
        });
        employee.save();
      },

      signOut(employee) {
        employee.setProperties({
          signOutTS: (0, _moment.default)().unix(),
          signOutBy: this.get('user.email')
        });
        employee.save();
      },

      adjustSignIn(item, start, end, range) {
        item.setProperties({
          adjustedSignInTS: range.startDate.unix(),
          adjustedSignInBy: this.get('user.email')
        });
        item.save();
      },

      adjustSignOut(item, start, end, range) {
        item.setProperties({
          adjustedSignOutTS: range.endDate.unix(),
          adjustedSignOutBy: this.get('user.email')
        });
        item.save();
      },

      updateComments(item, value) {
        item.set('comment', value);
        item.save();
      },

      updateRating(item, value) {
        item.set('rating', value);
        item.save();
      },

      toggleLunchBreak(item, value) {
        item.set('hasLunch', !value);
        item.save();
      },

      submitPunchCard() {
        // Remove company from employee available locations if client gave rating of 0
        const badEmployees = this.get('punchCard.badEmployeeCards');

        if (!(0, _lodash.isEmpty)(badEmployees)) {
          this.get('ajax').request('/api/employee/remove-location', {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=utf-8'
            },
            data: {
              badEmployees
            }
          }).catch(err => {
            this.get('notificationMessages').error("Employee(s) did not get banned from this location successfully", {
              autoClear: true,
              clearDuration: 2000
            });
            console.log(err);
          });
        } // Submit timesheet


        this.set('isSubmitting', true);
        const company = this.get('user.company');
        this.get('punchCard').set('submitter', this.get('user'));
        this.get('ajax').request('/api/punch-card/submit', {
          method: 'POST',
          headers: {
            'content-type': 'application/json; charset=utf-8'
          },
          data: {
            email: [this.get('user.email')],
            ccEmails: company.get('personnelEmails'),
            company: this.get('punchCard').belongsTo('company').value().toJSON(),
            employees: this.get('punchCard.occupiedEmployeeCards').map(x => x.toJSON())
          }
        }).then(() => {
          // add a notification to confirm submission
          this.get('notificationMessages').success('Form submitted successfully!', {
            autoClear: true,
            clearDuration: 2000
          });
          this.set('reviewMode', !this.get('reviewMode'));
          this.get('punchCard').set('isSubmitted', true);
          this.get('punchCard').save();
        }).catch(err => {
          this.get('notificationMessages').error("Form didn't get submitted successfully", {
            autoClear: true,
            clearDuration: 2000
          });
          console.log(err);
        }).finally(() => {
          this.set('isSubmitting', false);
        });
      },

      /* Prompt dialog */
      openSubmissionDialog()
      /* param, event */
      {
        this.set('dialogOrigin', null);
        this.set('showPromptDialog', true);
      },

      closePromptDialog(haveConfimation) {
        if (haveConfimation) {
          this.get('notificationMessages').info("Submitting..", {
            autoClear: true,
            clearDuration: 1000
          });
          this.send('submitPunchCard');
        }

        this.set('showPromptDialog', false);
      }

    }
  });

  _exports.default = _default;
});