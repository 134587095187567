define("orion-abbey/utils/validate-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateEmail;

  function validateEmail(email) {
    const EMAIL_REGEX = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$", "gm");
    return EMAIL_REGEX.test(email);
  }
});