define("orion-abbey/templates/components/employees-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xj9ubk7M",
    "block": "{\"symbols\":[\"card\",\"company\",\"employee\",\"controls\",\"title\",\"text\"],\"statements\":[[0,\"\\n\\n\"],[4,\"paper-card\",null,[[\"class\"],[\"settings-card\"]],{\"statements\":[[4,\"component\",[[22,1,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[22,5,[\"text\"]]],null,{\"statements\":[[0,\"      \"],[4,\"component\",[[22,6,[\"headline\"]]],null,{\"statements\":[[0,\"Employees Manager\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"component\",[[22,6,[\"subhead\"]]],null,{\"statements\":[[0,\"Where you can remove clients' employees\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[6]},null]],\"parameters\":[5]},null],[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[4,\"paper-list\",null,[[\"class\"],[\"services-container\"]],{\"statements\":[[0,\"      \"],[4,\"paper-subheader\",null,null,{\"statements\":[[0,\"Employees Managers \"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[27,\"sort-by\",[\"name\",[23,[\"companies\"]]],null]],null,{\"statements\":[[0,\"        \"],[4,\"paper-subheader\",null,[[\"class\"],[\"services-container\"]],{\"statements\":[[1,[22,2,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[22,2,[\"employees\"]]],null,{\"statements\":[[4,\"paper-item\",null,null,{\"statements\":[[0,\"            \"],[7,\"p\"],[11,\"class\",\"employee-manager-email-item\"],[9],[1,[22,3,[\"email\"]],false],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"md-secondary-container\"],[9],[0,\"\\n              \"],[4,\"paper-button\",null,[[\"class\",\"mini\",\"onClick\"],[\"right back-layer\",true,[27,\"action\",[[22,0,[]],\"removeEmployee\",[22,3,[]]],null]]],{\"statements\":[[1,[27,\"paper-icon\",[\"clear\"],null],false]],\"parameters\":[]},null],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "orion-abbey/templates/components/employees-manager.hbs"
    }
  });

  _exports.default = _default;
});