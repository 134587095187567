define("orion-abbey/controllers/manage", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    global: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    datePickerLowerBound: (0, _moment.default)().add(1, 'hours'),
    isSubmitting: false,
    isPunchCardAssignDisabled: Ember.computed('punchCard.eventId', 'todaysEventShifts.@each.name}', function () {
      if (!this.get('todaysEventShifts')) return true;
      return this.get('todaysEventShifts').isEvery('Employee', 0);
    }),
    isSubmitDisabled: Ember.computed('listData.{[],@each.count}', 'isSubmitting', function () {
      if (!this.get('listData').length) return true;
      if (this.get('isSubmitting')) return true;
      return (0, _lodash.isEmpty)(this.get('listData').reject(x => x.get('count') < 1));
    }),
    todaysEventHeaderText: Ember.computed('todaysEventShifts', function () {
      if ((0, _lodash.isEmpty)(this.get('todaysEventShifts'))) return 'There are no shifts today';
      return `Today's shifts`;
    }),
    actions: {
      setDateRange(item, start, end, range) {
        item.setProperties({
          startDateTime: range.startDate.unix(),
          endDateTime: range.endDate.unix()
        });
      },

      addItem() {
        this.listData.pushObject(Ember.Object.create({
          count: 1,
          type: this.get('roles.firstObject'),
          comment: '',
          startDateTime: (0, _moment.default)().add(1, 'hours').unix(),
          endDateTime: (0, _moment.default)().add(3, 'hours').unix()
        }));
      },

      removeItem(item) {
        this.listData.removeObject(item);
      },

      submitShifts(data) {
        let shifts = (0, _lodash.chain)((0, _lodash.cloneDeep)(data)).map(shift => (0, _lodash.pick)(shift, ['count', 'startDateTime', 'endDateTime', 'type', 'comment'])).value();
        const hasEventsStartingInThePast = (0, _lodash.some)(shifts, shift => shift.startDateTime < (0, _moment.default)().unix());
        const hasEventsOver24Hours = (0, _lodash.some)(shifts, ({
          startDateTime,
          endDateTime
        }) => _moment.default.duration(_moment.default.unix(endDateTime).diff(_moment.default.unix(startDateTime))).as('hours') > 24);

        if (hasEventsStartingInThePast) {
          this.get('notificationMessages').warning('One or more shifts are starting in the past! Can\'t submit.', {
            autoClear: true,
            clearDuration: 4000
          });
          return;
        }

        if (hasEventsOver24Hours) {
          this.get('notificationMessages').warning('One or more shifts are over 24 hours long! Can\'t submit.', {
            autoClear: true,
            clearDuration: 4000
          });
          return;
        }

        this.set('isSubmitting', true);
        this.get('ajax').request('/api/shifts/create', {
          method: 'POST',
          headers: {
            'content-type': 'application/json; charset=utf-8'
          },
          data: {
            shifts: shifts
          }
        }).then(() => {
          this.get('notificationMessages').success('Shifts submitted successfully!', {
            autoClear: true,
            clearDuration: 2000
          });
          this.transitionToRoute('manage');
          window.location.reload(true);
        }).catch(err => {
          console.log(err);
          this.get('notificationMessages').error(`Shifts did not submit successfully`, {
            autoClear: true,
            clearDuration: 2000
          });
        }).finally(() => {
          this.set('isSubmitting', false);
        });
      },

      async showPunchCard() {
        // if there exists a punchCard use that, otherwise create a new punchCard
        let punchCard = null;
        const todaysEventId = (0, _moment.default)().format('MMDDYYYY').toString();
        let activePunchCard = await this.store.query('punch-card', {
          orderBy: 'eventId',
          equalTo: todaysEventId
        }).then(pcs => pcs.filter(pc => pc.get('company.id') == this.get('company.id'), this), this).then(pcs => pcs.get('firstObject'));
        const hasActivePunchCard = !(0, _lodash.isEmpty)(activePunchCard);

        if (hasActivePunchCard) {
          punchCard = activePunchCard;
        } else {
          punchCard = this.store.createRecord('punch-card', {
            eventId: todaysEventId,
            isSubmitted: false,
            company: this.get('company')
          });
        }

        let newPunchCardEmployeeCards = this.get('todaysEventShifts').map(shift => {
          let emplyeeInfo = shift._DPMetaData.EmployeeInfo; // handle empty employee object

          if ((0, _lodash.isEmpty)(emplyeeInfo)) {
            this.get('notificationMessages').info("A shift has no staff assigned", {
              autoClear: true,
              clearDuration: 2000
            });
            return;
          }

          let empCard = this.store.createRecord('employee-card', {
            name: emplyeeInfo.DisplayName,
            role: shift._DPMetaData.OperationalUnitInfo.OperationalUnitName,
            shiftId: shift.Id,
            companyId: this.get('company.companyId'),
            employeeId: emplyeeInfo.Id,
            image: emplyeeInfo.Photo,
            rating: 2,
            comment: "",
            startTS: shift.StartTime,
            endTS: shift.EndTime,
            signInTS: null,
            signOutTS: null,
            adjustedSignInTS: null,
            adjustedSignOutTS: null
          });
          return empCard;
        }).without(undefined);
        let currentPunchCardOccupiedEmployeeCards = await punchCard.get('employeeCards');
        currentPunchCardOccupiedEmployeeCards = currentPunchCardOccupiedEmployeeCards.toArray();
        newPunchCardEmployeeCards = (0, _lodash.unionBy)(currentPunchCardOccupiedEmployeeCards, newPunchCardEmployeeCards, x => `${x.shiftId}-${x.employeeId}`);
        punchCard.set('employeeCards', newPunchCardEmployeeCards);
        Ember.RSVP.all(newPunchCardEmployeeCards.map(empCard => empCard.save())).then(() => punchCard.save()).then(() => this.transitionToRoute('event-log')).catch(err => {
          this.get('notificationMessages').error("Failed to create Event Log", {
            autoClear: true,
            clearDuration: 2000
          });
          console.log(err);
        });
      }

    }
  });

  _exports.default = _default;
});