define("orion-abbey/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend();

  _exports.default = _default;
});