define("orion-abbey/helpers/range-text", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rangeText = rangeText;
  _exports.default = void 0;

  function rangeText([start, ...rest]) {
    if (rest.length <= 0) {
      if (!(0, _lodash.isNil)(start)) return _moment.default.unix(start).format('MMM D, h:mm A');
      return '';
    } else {
      let end = (0, _lodash.head)(rest);

      if (!(0, _lodash.isNil)(start) && !(0, _lodash.isNil)(end)) {
        return `${_moment.default.unix(start).format('MMM D, h:mm A')} To ${_moment.default.unix(end).format('MMM D, h:mm A')}`;
      } else return `${(0, _moment.default)().add(24, 'hours').format('MMM D h:mm A')} To ${(0, _moment.default)().add(30, 'hours').format('MMM D h:mm A')}`;
    }
  }

  var _default = Ember.Helper.helper(rangeText);

  _exports.default = _default;
});