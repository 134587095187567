define("orion-abbey/controllers/login", ["exports", "lodash", "orion-abbey/utils/validate-email"], function (_exports, _lodash, _validateEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      async authenticate() {
        let {
          identification,
          password
        } = this.getProperties('identification', 'password');
        await this.get('store').query('user', {
          orderBy: 'email',
          equalTo: this.get('identification')
        }).then(storeUser => {
          if ((0, _lodash.isEmpty)(storeUser.get('firstObject'))) {
            this.get('notificationMessages').warning("User does not exist or no longer active.", {
              autoClear: true,
              clearDuration: 2000
            });
            return;
          }

          this.get('session').authenticate('authenticator:torii', 'firebase', {
            "provider": 'password',
            "email": identification,
            "password": password
          }).catch(reason => {
            console.log(reason);
            this.set('errorMessage', reason.error || reason);
          });
        });
      },

      resetPassword() {
        let email = this.get('identification');

        if ((0, _lodash.isEmpty)(email)) {
          this.get('notificationMessages').warning("Please enter your email to reset password", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        if (!(0, _validateEmail.default)(email)) {
          this.get('notificationMessages').warning("Please enter a valid email to reset password", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        const auth = this.get('firebaseApp').auth();
        auth.sendPasswordResetEmail(this.get('identification')).then(() => {
          this.get('notificationMessages').success("Password reset successful. Check your email", {
            autoClear: true,
            clearDuration: 2000
          });
        }).catch(err => {
          console.log(err);

          if (err.code && err.code == 'auth/user-not-found') {
            this.get('notificationMessages').warning("Email does not exist!", {
              autoClear: true,
              clearDuration: 2000
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});