define("orion-abbey/components/company-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    actions: {
      async updateManager(employee, value) {
        employee.set('isManager', value);
        employee.save().then(() => this.get('notificationMessages').success("Employee updated!", {
          autoClear: true,
          clearDuration: 2000
        }));
      }

    }
  });

  _exports.default = _default;
});