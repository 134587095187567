define("orion-abbey/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        // Needed for event-log setupController hook to work as expected. The store need to be loaded
        companies: this.store.findAll('company'),
        // needs to be pulled in early, otherwise it lazy loads at timesheet
        // submission time and fails to include the personnel in ccEmails
        personnels: this.store.findAll('personnel')
      });
    }

  });

  _exports.default = _default;
});