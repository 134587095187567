define("orion-abbey/components/add-company", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    notificationMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    companyName: '',
    actions: {
      async add() {
        let name = this.get('companyName'); // check if company already exists

        let company = await this.get('store').query('company', {
          orderBy: 'name',
          equalTo: name
        });

        if (!(0, _lodash.isEmpty)(company.content)) {
          this.get('notificationMessages').success("Company already added!", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        this.get('ajax').request('/api/company', {
          method: 'POST',
          headers: {
            'content-type': 'application/json; charset=utf-8'
          },
          data: {
            name
          }
        }).then(data => {
          let company = this.get('store').createRecord('company', data);
          company.save();
          this.get('notificationMessages').success("Company added!", {
            autoClear: true,
            clearDuration: 2000
          });
        }).catch(err => {
          this.get('notificationMessages').error(err.payload, {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }

    }
  });

  _exports.default = _default;
});