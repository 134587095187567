define("orion-abbey/routes/manage", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "lodash"], function (_exports, _authenticatedRouteMixin, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    global: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        user: this.get('global').getCurrentUserPromise(),
        punchCards: Ember.RSVP.all(this.get('global').getPunchCardsPromises())
      });
    },

    setupController(controller, model) {
      const user = model.user.get('firstObject'); // Set PunchCard

      let company = user.get('company');
      let punchCards = (0, _lodash.flatten)(model.punchCards.toArray().map(val => val.toArray())).filterBy('company.id', company.get('id'));
      let latestPunchCard = punchCards.get('firstObject');
      controller.setProperties({
        punchCards: punchCards,
        punchCard: latestPunchCard,
        company: user.get('company').content,
        roles: (0, _lodash.values)(user.get('company.roles')),
        listData: []
      }); // Get upcoming shifts

      this.get('ajax').request('/api/shifts/upcoming', {
        method: 'POST',
        headers: {
          'content-type': 'application/json; charset=utf-8'
        },
        data: {
          lowerBoundTS: (0, _moment.default)().startOf('day').unix(),
          roles: controller.get('roles').mapBy('id')
        }
      }).then(upcomingShifts => {
        const groupedUpcomingShifts = (0, _lodash.groupBy)(upcomingShifts, shift => _moment.default.unix(shift.StartTime).format('dddd, MMMM Do YYYY'));
        const firstEventKey = (0, _lodash.chain)(groupedUpcomingShifts).keys().head().value(); // set todays event shifts

        if ((0, _moment.default)(firstEventKey, 'dddd, MMMM Do YYYY').isBefore((0, _moment.default)().endOf('day'))) {
          controller.set('todaysEventShifts', groupedUpcomingShifts[firstEventKey]);
          (0, _lodash.unset)(groupedUpcomingShifts, firstEventKey);
        }

        if ((0, _lodash.isEmpty)(upcomingShifts)) {
          controller.setProperties({
            upcomingShifts: [],
            groupedUpcomingShifts: []
          });
          return;
        } // set upcoming events shifts grouped by day


        controller.setProperties({
          upcomingShifts: upcomingShifts,
          groupedUpcomingShifts: groupedUpcomingShifts
        });
      });
    }

  });

  _exports.default = _default;
});