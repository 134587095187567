define("orion-abbey/routes/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    global: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        companies: this.store.findAll('company'),
        employees: this.store.findAll('user'),
        user: this.get('global').getCurrentUserPromise()
      });
    },

    afterModel(model, transition) {
      // transition to manage route if no current user is not an admin
      let currentUser = model.user.get('firstObject');
      if (!currentUser.isOwner && !currentUser.isManager) this.transitionTo('manage');
    },

    setupController(controller, model) {
      const user = model.user.get('firstObject');
      const companies = user.get('isOwner') == false ? model.companies.filterBy('id', user.get('company.id')) : model.companies;
      controller.setProperties({
        companies: companies,
        user: user
      });
    }

  });

  _exports.default = _default;
});