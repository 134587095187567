define("orion-abbey/components/sign-up-form", ["exports", "lodash", "orion-abbey/utils/validate-email"], function (_exports, _lodash, _validateEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notificationMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    tooltipPosition: 'bottom',
    firstName: '',
    lastName: '',
    email: '',
    company: null,
    isOwner: false,
    isManager: false,

    resetComponentState() {
      this.setProperties({
        firstName: '',
        lastName: '',
        email: '',
        company: null,
        isOwner: false,
        isManager: false
      });
    },

    actions: {
      async add() {
        let email = this.get('email');

        if (!(0, _validateEmail.default)(email)) {
          this.get('notificationMessages').warning("Please enter a valid email", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        } // check if company already exists


        let user = await this.get('store').query('user', {
          orderBy: 'email',
          equalTo: this.get('email')
        });

        if (!(0, _lodash.isEmpty)(user.content)) {
          this.get('notificationMessages').success("Employee already exists!", {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        let newUser = this.get('store').createRecord('user', {
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          email: this.get('email'),
          company: this.get('company'),
          isOwner: this.get('isOwner'),
          isManager: this.get('isManager')
        });
        const auth = this.get('firebaseApp').auth();
        this.get('notificationMessages').info("Adding new employee..", {
          autoClear: true,
          clearDuration: 2000
        });
        this.get('company').get('employees').addObject(newUser);
        newUser.save().then(() => this.get('company').save()).then(() => auth.createUserWithEmailAndPassword(this.get('email'), '123456')) // Create new user
        .then(() => auth.sendPasswordResetEmail(this.get('email'))) // Send Update Pass email to new user
        .then(() => {
          this.get('notificationMessages').success("Employee added successfully!", {
            autoClear: true,
            clearDuration: 2000
          }); // Reset Form

          this.resetComponentState();
        }).catch(err => {
          console.log(err);

          if (err.code && err.code == 'auth/email-already-in-use') {
            auth.sendPasswordResetEmail(this.get('email'));
            this.get('notificationMessages').warning("Employee added. Email already in use.", {
              autoClear: true,
              clearDuration: 2000
            });
            this.resetComponentState();
          } else this.get('notificationMessages').warning("Couldn't add new employee.", {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }

    }
  });

  _exports.default = _default;
});