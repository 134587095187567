define("orion-abbey/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/froeLia",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"user\",\"isOwner\"]]],null,{\"statements\":[[0,\"  \"],[4,\"add-company\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"company-manager\",null,[[\"companies\"],[[23,[\"companies\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"employees-manager\",null,[[\"companies\"],[[23,[\"companies\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"sign-up-form\",null,[[\"companies\",\"user\"],[[23,[\"companies\"]],[23,[\"user\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[4,\"personnel-form\",null,[[\"user\"],[[23,[\"user\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "orion-abbey/templates/settings.hbs"
    }
  });

  _exports.default = _default;
});