define("orion-abbey/models/punch-card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    eventId: _emberData.default.attr('string'),
    isSubmitted: _emberData.default.attr('boolean'),
    submitter: _emberData.default.belongsTo('user', {
      async: true,
      inverse: null
    }),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    employeeCards: _emberData.default.hasMany('employee-card', {
      async: true,
      inverse: null
    }),
    occupiedEmployeeCards: Ember.computed.filterBy('employeeCards', 'isOccupied', true),
    badEmployeeCards: Ember.computed.filterBy('occupiedEmployeeCards', 'rating', 0)
  });

  _exports.default = _default;
});