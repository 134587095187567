define("orion-abbey/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    companyId: _emberData.default.attr('number'),
    address: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    name: _emberData.default.attr('string', {
      defaultValue: ""
    }),
    employees: _emberData.default.hasMany('user', {
      async: true,
      inverse: null
    }),
    personnels: _emberData.default.hasMany('personnel', {
      async: true,
      inverse: null
    }),
    roles: _emberData.default.attr(),
    personnelEmails: Ember.computed('personnels.@each.email', function () {
      return this.get('personnels').mapBy('email');
    })
  });

  _exports.default = _default;
});