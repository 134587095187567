define("orion-abbey/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel()
    /* transition */
    {
      if (!this.get('session').isAuthenticated) this.transitionTo('login');else this.transitionTo('manage');
    }

  });

  _exports.default = _default;
});