define("orion-abbey/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IvKRmr87",
    "block": "{\"symbols\":[\"toolbar\"],\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"component\",[[22,1,[\"tools\"]]],[[\"class\"],[\"app-header\"]],{\"statements\":[[0,\"      \"],[4,\"paper-button\",null,[[\"href\"],[[27,\"href-to\",[\"index\"],null]]],{\"statements\":[[0,\"Welcome\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"        \"],[4,\"paper-button\",null,[[\"href\"],[[27,\"href-to\",[\"event-log\"],null]]],{\"statements\":[[0,\"Event Log\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"app-routes-container\"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"mini\",\"raised\",\"href\"],[true,true,[27,\"href-to\",[\"settings\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"paper-icon\",[\"settings\"],null],false],[0,\"\\n\"],[4,\"paper-tooltip\",null,[[\"position\"],[\"bottom\"]],{\"statements\":[[0,\"                Settings\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"paper-button\",null,[[\"mini\",\"raised\",\"onClick\"],[true,true,[27,\"action\",[[22,0,[]],\"invalidateSession\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"paper-icon\",[\"login\"],null],false],[0,\"\\n\"],[4,\"paper-tooltip\",null,[[\"position\"],[\"bottom\"]],{\"statements\":[[0,\"                Logout\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[4,\"paper-button\",null,[[\"class\",\"href\"],[\"login-button\",[27,\"href-to\",[\"login\"],null]]],{\"statements\":[[0,\"Login\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[27,\"notification-container\",null,[[\"position\"],[\"top\"]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "orion-abbey/templates/application.hbs"
    }
  });

  _exports.default = _default;
});