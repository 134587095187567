define("orion-abbey/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive"], function (_exports, _adaptive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adaptive.default.extend({
    persist(data) {
      const {
        authenticator,
        email,
        provider,
        refreshToken,
        uid
      } = data.authenticated;
      const subset = {
        authenticator,
        email,
        provider,
        refreshToken,
        uid
      };
      return this._super({
        authenticated: subset
      });
    }

  });

  _exports.default = _default;
});