define("orion-abbey/models/employee-card", ["exports", "ember-data", "moment", "lodash"], function (_exports, _emberData, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    shiftId: _emberData.default.attr('number'),
    companyId: _emberData.default.attr('number'),
    employeeId: _emberData.default.attr('number'),
    image: _emberData.default.attr('string'),
    punchCard: _emberData.default.belongsTo('punch-card', {
      async: true,
      inverse: null
    }),
    startTS: _emberData.default.attr('number'),
    endTS: _emberData.default.attr('number'),
    rating: _emberData.default.attr('number', {
      default: 2
    }),
    comment: _emberData.default.attr('string', {
      default: ""
    }),
    signInTS: _emberData.default.attr('number', {
      default: (0, _moment.default)().unix()
    }),
    signOutTS: _emberData.default.attr('number', {
      default: (0, _moment.default)().unix()
    }),
    signInBy: _emberData.default.attr('string'),
    signOutBy: _emberData.default.attr('string'),
    adjustedSignInTS: _emberData.default.attr('number', {
      default: (0, _moment.default)().unix()
    }),
    adjustedSignOutTS: _emberData.default.attr('number', {
      default: (0, _moment.default)().unix()
    }),
    adjustedSignInBy: _emberData.default.attr('string'),
    adjustedSignOutBy: _emberData.default.attr('string'),
    hasLunch: _emberData.default.attr('boolean', {
      default: false
    }),
    hasLunchLabel: Ember.computed('hasLunch', function () {
      switch (this.get('hasLunch')) {
        case false:
          return 'NO';

        case true:
          return 'YES';
      }
    }),
    isOccupied: Ember.computed.notEmpty('employeeId'),
    ratingText: Ember.computed('rating', function () {
      switch (this.get('rating')) {
        case -1:
          return 'NC/NS';

        case 0:
          return 'Low';

        case 1:
          return 'Medium';

        case 2:
          return 'High';
      }
    }),
    imagePath: Ember.computed('image', function () {
      return this.get('image') ? this.get('image') : '/images/pexels-photo-235805.jpeg';
    }),
    displayName: Ember.computed('name', function () {
      return this.get('name') ? this.get('name') : 'Available';
    }),
    shiftText: Ember.computed('startTS', 'endTs', 'role', function () {
      let start = _moment.default.unix(this.get('startTS')).format("MMM D, h:mm A");

      let end = _moment.default.unix(this.get('endTS')).format("MMM D, h:mm A");

      let role = this.get('role');
      return `${role}: ${start} - ${end}`;
    }),
    signInText: Ember.computed('signInTS', function () {
      if ((0, _lodash.isNumber)(this.get('signInTS'))) return _moment.default.unix(this.get('signInTS')).format("MMM D, h:mm A");
      return 'N/A';
    }),
    signOutText: Ember.computed('signOutTS', function () {
      if ((0, _lodash.isNumber)(this.get('signOutTS'))) return _moment.default.unix(this.get('signOutTS')).format("MMM D, h:mm A");
      return 'N/A';
    })
  });

  _exports.default = _default;
});