define("orion-abbey/templates/components/add-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QeJXiVtC",
    "block": "{\"symbols\":[\"card\",\"form\",\"title\",\"text\"],\"statements\":[[0,\"\\n\"],[4,\"paper-card\",null,[[\"class\"],[\"settings-card\"]],{\"statements\":[[4,\"component\",[[22,1,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[22,3,[\"text\"]]],null,{\"statements\":[[0,\"      \"],[4,\"component\",[[22,4,[\"headline\"]]],null,{\"statements\":[[0,\"Company Add Form\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"component\",[[22,4,[\"subhead\"]]],null,{\"statements\":[[0,\"Type up a company name that already exists on Deputy Platform\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null],[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[4,\"paper-form\",null,[[\"onSubmit\"],[[27,\"action\",[[22,0,[]],\"add\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"layout-row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"layout-column flex-90\"],[9],[0,\"\\n          \"],[1,[27,\"paper-input\",null,[[\"class\",\"label\",\"placeholder\",\"value\",\"onChange\"],[\"flex-30 company-name-input\",\"Company Name\",\"Very Important People\",[23,[\"companyName\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"companyName\"]]],null]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"layout-row right\"],[9],[0,\"\\n        \"],[4,\"component\",[[22,2,[\"submit-button\"]]],[[\"raised\"],[true]],{\"statements\":[[0,\"Add\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "orion-abbey/templates/components/add-company.hbs"
    }
  });

  _exports.default = _default;
});